import { API_ENV } from '@env'
import { Fragment, ReactNode, useContext, useMemo } from 'react'
import { Image, ImageSourcePropType, ImageStyle, View } from 'react-native'
import trackMapImageMock from '../../../../assets/images/track-map-image-mock.jpg'
import { NamedObj } from '../../../../helper-types'
import {
  breakpointAbove,
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../../styles/text-sizes'
import { humanFriendlyDate } from '../../../../utils/date-time'
import { Heading_C } from '../../../Base/Heading/Heading'
import { Text_C } from '../../../Base/Text/Text'
import { PrintButton_C } from '../../../Partials/PrintButton'
import { MeetingSelect_C } from '../../../Partials/_meeting-components/MeetingSelect'
import { WeatherIcon_C } from '../../../Partials/_meeting-components/WeatherIcon'
import { MeetingContext } from '../Meeting'
import { RaceTabs_C } from './RaceTabs'
import { printIcon } from '../../../Base/Icon/preset-icon-props'
import { Button_C } from '../../../Base/Button/Button'
import { GlobalContext } from '../../../../global-context'
import { Switch_C } from '../../../Base/Switch/Switch'
import { useAtom } from 'jotai'
import { includeRunnerTrialsAtom } from '../../../../atoms'

export function MeetingHeader_C() {
  const { todaysMeetings, activeMeeting, activeMeetingBasic } = useContext(MeetingContext)
  const { openModal } = useContext(GlobalContext)

  const styles = useBreakpointStyles({ styles: breakpointStyles })

  const isBelowMedium = breakpointBelow('medium')
  const isAboveXxl = breakpointAbove('xxlarge')

  const layoutType = isBelowMedium ? 'small' : isAboveXxl ? 'large' : 'medium'

  const { track, weather, trackCondition } = activeMeeting
  const { trackDetails } = track

  const {
    trackSelect,
    meetingDate,
    trackInfo,
    printIconButton,
    trackImage,
    isTrialElem,
    raceTabs,
    weatherAndTrackCondition
  } = getElements()

  const smallLayout = (
    <Fragment>
      <View style={styles.topContainer}>
        <View style={styles.leftContainer}>
          {trackSelect}
          {meetingDate}
          {isTrialElem}

          <View style={{ flexDirection: 'row', gap: 20 }}>
            {printIconButton}
            {weatherAndTrackCondition}
          </View>
        </View>
        <View style={styles.rightContainer}>
          {trackInfo}
          {trackImage}
        </View>
      </View>
      <View style={styles.bottomContainer}>{raceTabs}</View>
    </Fragment>
  )
  const mediumLayout = (
    <Fragment>
      <View style={styles.topContainer}>
        <View style={styles.leftContainer}>
          {trackSelect}
          {meetingDate}
          {isTrialElem}
          <View style={{ flexDirection: 'row', gap: 20 }}>
            {printIconButton}
            {weatherAndTrackCondition}
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 10,
              gap: 20,
              alignItems: 'center'
            }}
          >
            {trackInfo}
            {trackImage}
          </View>
        </View>
        <View style={styles.rightContainer}>{raceTabs}</View>
      </View>
    </Fragment>
  )

  const largeLayout = (
    <Fragment>
      <View style={styles.topContainer}>
        <View style={styles.leftContainer}>
          {trackSelect}
          {meetingDate}
          {isTrialElem}
          <View style={{ flexDirection: 'row', gap: 20 }}>
            {printIconButton}
            {weatherAndTrackCondition}
          </View>
        </View>
        <View style={styles.middleContainer}>
          {trackImage}
          {trackInfo}
        </View>
        <View style={styles.rightContainer}>{raceTabs}</View>
      </View>
    </Fragment>
  )
  return (
    <View style={styles.mainContainer}>
      {layoutType == 'small' ? smallLayout : layoutType == 'medium' ? mediumLayout : largeLayout}
    </View>
  )

  function getElements(): NamedObj<ReactNode> {
    const display = activeMeeting.status == 'Fields Drawn' || activeMeeting.status === 'Results'

    const lastRace = activeMeeting.races[activeMeeting.races.length - 1]

    const PrintModal = () => {
      const [includeRunnerTrials, setIncludeRunnerTrials] = useAtom(includeRunnerTrialsAtom)

      return (
        <View style={{ gap: 25 }}>
          <View style={{ gap: 5 }}>
            <Heading_C styleType="h1" style={{ lineHeight: 26 }}>
              {activeMeeting.track.name} {activeMeeting.track.state}
            </Heading_C>
            <Heading_C styleType="h2">{humanFriendlyDate(new Date(activeMeeting.date))}</Heading_C>
          </View>

          <View style={{ alignItems: 'center', rowGap: 10 }}>
            <View style={styles.expandSwitch}>
              <Text_C>Include trials</Text_C>

              <Switch_C
                onSwitched={(val) => {
                  setIncludeRunnerTrials(val)
                }}
                isEnabled={includeRunnerTrials}
              />
            </View>

            <PrintButton_C
              meeting={activeMeeting}
              printType="fields"
              includeText
              includeTrials={includeRunnerTrials}
            />
            <PrintButton_C
              meeting={activeMeeting}
              printType="form"
              includeText
              includeTrials={includeRunnerTrials}
            />

            {lastRace.status === 'Final Results' && (
              <PrintButton_C meeting={activeMeeting} printType="sectionals" includeText />
            )}
          </View>
        </View>
      )
    }

    const printIconButton = display && (
      <Button_C
        icon={printIcon}
        onPress={() =>
          openModal(<PrintModal />, {
            modalStyle: { modalInnerContainer: { maxWidth: 450 } }
          })
        }
        styleType="colorIconOnly"
      />
    )

    const raceTabs = <RaceTabs_C style={{ raceTabsContainer: styles.raceTabsContainer }} />
    const trackSelect = useMemo(() => {
      return !activeMeetingBasic ? (
        <Text_C style={{ fontWeight: '600SemiBold', ...textSizes.size7 }}>
          {activeMeeting.track.name}
        </Text_C>
      ) : (
        <MeetingSelect_C activeMeeting={activeMeetingBasic} meetings={todaysMeetings} />
      )
    }, [todaysMeetings, activeMeetingBasic])

    let trackInfo: JSX.Element | undefined
    if (trackDetails) {
      const {
        circumference,
        homeStraightLength,
        maxFrontRowMobile,
        maxFrontRowStanding,
        sprintLane
      } = trackDetails
      trackInfo = (
        <View>
          {circumference && (
            <Text_C style={styles.trackInfoText}>Circumference: {circumference}m</Text_C>
          )}
          {homeStraightLength && (
            <Text_C style={styles.trackInfoText}>Straight: {homeStraightLength}m</Text_C>
          )}

          {maxFrontRowMobile && (
            <Text_C style={styles.trackInfoText}>Front Line (Mobile): {maxFrontRowMobile}</Text_C>
          )}

          {maxFrontRowStanding && (
            <Text_C style={styles.trackInfoText}>
              Front Line (Standing): {maxFrontRowStanding}
            </Text_C>
          )}
          {sprintLane && <Text_C style={styles.trackInfoText}>Sprint Lane in Operation</Text_C>}
        </View>
      )
    }

    const isTrialElem = activeMeeting.isTrial && <Heading_C styleType="h4">Trial</Heading_C>

    const trackImageUrl = activeMeeting.track.trackMapImage?.viewerSources?.[0]?.url as
      | ImageSourcePropType
      | undefined

    const trackImage =
      !trackImageUrl && API_ENV == 'production' ? null : (
        <View style={styles.trackMapImageContainer}>
          <Image
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            source={trackImageUrl ?? trackMapImageMock}
            style={
              {
                ...styles.trackMapImage,
                ...(trackImageUrl ? {} : { aspectRatio: 1.7 })
              } as ImageStyle
            }
            resizeMode="contain"
            track-map-image-mock
          />
          {!trackImageUrl && (
            <Text_C style={styles.noTrackMapImageMessage}>
              {'map not uploaded yet\naspect ratio: 1.7'}
            </Text_C>
          )}
        </View>
      )

    const { date } = useContext(MeetingContext)
    const meetingDate = useMemo(
      () => <Text_C style={styles.meetingDate}>{humanFriendlyDate(new Date(date))}</Text_C>,
      [date]
    )

    const weatherAndTrackCondition = (weather || trackCondition) && (
      <View style={styles.weatherContainer}>{weather && <WeatherIcon_C weather={weather} />}</View>
    )
    return {
      raceTabs,
      trackSelect,
      meetingDate,
      trackInfo,
      printIconButton,
      weatherAndTrackCondition,
      trackImage,
      isTrialElem
    }
  }
}

const breakpointStyles = createStyles({
  mainContainer: {
    gap: 20,
    flex: 1
  },
  topContainer: {
    base: {
      justifyContent: 'space-between',
      gap: 10,
      flex: 1
    },
    medium: {
      flexDirection: 'row',
      gap: 40
    }
  },
  leftContainer: {
    base: {
      marginRight: 'auto',
      gap: 4
    },
    medium: { gap: 6 },
    large: { gap: 8 }
  },
  middleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 20
  },
  rightContainer: {
    base: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 20,
      flex: 1
    },
    medium: {
      alignItems: 'flex-start',
      justifyContent: 'flex-end'
    },

    xxlarge: {
      alignItems: 'center'
    }
  },
  bottomContainer: {
    justifyContent: 'flex-end'
  },
  trackInfoText: {
    base: { fontWeight: '600SemiBold', ...textSizes.size1 },
    [tabletBreakpoint]: {
      ...textSizes.size2
    }
  },
  raceTabsContainer: {},
  meetingDate: {
    base: {
      fontFamily: 'OpenSans',
      fontWeight: '600SemiBold',
      textTransform: 'uppercase',
      ...textSizes.size3,
      marginBottom: 10
    },
    [desktopBreakpoint]: {
      ...textSizes.size4
    }
  },

  expandSwitch: {
    base: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20,
      marginBottom: 8
    }
  },

  weatherContainer: {
    base: {
      gap: 12,
      flexDirection: 'row',
      alignItems: 'center'
    }
  },

  weather: {
    base: {
      flexDirection: 'row',
      columnGap: 2,
      alignItems: 'center'
    }
  },

  trackCondition: {
    base: {
      flexDirection: 'row',
      columnGap: 5
    }
  },
  trackMapImageContainer: {
    base: {
      alignItems: 'center',
      justifyContent: 'center',
      aspectRatio: 1.5,
      height: 100
    },
    medium: {
      height: 120
    }
  },
  trackMapImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  },
  noTrackMapImageMessage: {
    base: {
      backgroundColor: '#ffffffbb',
      padding: 10,
      fontWeight: '700Bold',
      textAlign: 'center',
      ...textSizes.size3
    }
  }
})
