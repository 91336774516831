import { fetchData } from '../api-fetch-request'
import { upperCase } from 'lodash'
import {
  VideoReplayShowResponse,
  VideoReplayShowFetchResponse
} from '../data-types/fetch-response-types/video-replays-response.type'
import { AustralianState } from '../../../types/map.type'
import { convertAsset } from '../../../utils/assets'

export type VideoSourceResponse = {
  playerSources: {
    url: string
  }[]
}

type Params = {
  date: string
  trackSlug: string
  raceNumber: number
  isTrial: boolean
}

export const getVideoReplay = ({
  date,
  trackSlug,
  raceNumber,
  isTrial = false
}: Params): Promise<VideoReplayShowResponse> => {
  const endpoint = `videos/replays/${trackSlug}/${date}/${raceNumber}?trial=${isTrial}`

  return fetchData<VideoReplayShowResponse, VideoReplayShowFetchResponse>({
    endpoint,
    convertResponse,
    validationCompareObject: undefined
  })

  function convertResponse(payload: VideoReplayShowFetchResponse): VideoReplayShowResponse {
    const { race } = payload

    return {
      meeting: {
        date: race.meeting.date,
        isTrial: race.meeting.is_trial,
        dayPhaseLetter: race.meeting.day_night_twilight,
        track: {
          name: race.meeting.track.desktop_display_name,
          shortName: race.meeting.track.mobile_display_name,
          slug: race.meeting.track.slug,
          state: upperCase(race.meeting.track.state) as AustralianState
        }
      },
      name: race.name,
      raceNumber: race.race_number,
      startTime: race.scheduled_start_time,
      raceReplay: convertAsset(race.replay_video),
      hasStewardsReplay: race.has_stewards_replay,
      stewardReplays: race.stewards_replay_videos.map((v) => convertAsset(v, true))
    }
  }
}
