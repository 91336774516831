/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-unused-vars */
import { PropsWithChildren, useState } from 'react'

import { Image, View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'
import { colors } from '../../../styles/colors'
import { Button_C } from '../../Base/Button/Button'
import { Heading_C } from '../../Base/Heading/Heading'
import { IconProps } from '../../Base/Icon/Icon'
import { ScrollHorizontal_C } from '../../Base/ScrollHorizontal/ScrollHorizontal'
import { Separator_C } from '../../Base/Separator/Separator'
import { Card_C } from '../Card/Card'
import { Link_C } from '../../Base/Link/Link'

export type TabConfigItem<TabName extends string = string> = [
  TabName,
  JSX.Element,
  {
    lowPriority?: boolean
    buttonIcon?: IconProps
    hidden?: boolean
    label?: string
  }?
]

type Props<TabName extends string = string> = {
  initialActiveTab?: TabName
  tabs: TabConfigItem<TabName>[]
  onTabSelected?(tabName: TabName): void
  heading?: string | JSX.Element
  style?: NamedBreakpointStylesExtension<typeof breakpointStyles>
  separateCards?: boolean
  rasUrl?: string
}

export function TabView_C<TabName extends string = string>(
  props: PropsWithChildren<Props<TabName>>
) {
  const { initialActiveTab, tabs, style, heading, children, separateCards = true } = props

  function comparePriority(a: TabConfigItem<TabName>, b: TabConfigItem<TabName>) {
    const ap = a[2]?.lowPriority
    const bp = b[2]?.lowPriority
    if (!ap && bp) {
      return -1
    }
    if (ap && !bp) {
      return 1
    }
    return 0
  }

  const sortedAndFilteredTabs = tabs.filter((t) => !t[2]?.hidden).sort(comparePriority)

  const [[firstTabName]] = sortedAndFilteredTabs

  const [activeTab, setActiveTab] = useState(initialActiveTab ?? firstTabName)

  const styles = useBreakpointStyles({
    styles: breakpointStyles,
    additionalStyles: [style]
  })

  const outputView = tabs.reduce(
    (outputView: JSX.Element | null, [name, view]): JSX.Element | null => {
      return name == activeTab ? view : outputView
    },
    null
  )

  const header = (heading || children) && (
    <>
      <View style={styles.topContainer}>
        {heading && (
          <View style={styles.leftContainer}>
            {heading && typeof heading == 'string' ? (
              <Heading_C styleType="h1">{heading}</Heading_C>
            ) : (
              heading
            )}
          </View>
        )}

        <View style={styles.rightContainer}>{children}</View>
      </View>
      <Separator_C />
    </>
  )

  const tabsElem = (
    <ScrollHorizontal_C contentContainerStyle={styles.scrollContainer}>
      {props.rasUrl && (
        <Link_C linkUrl={props.rasUrl} externalLink newTab>
          <View style={{ width: 65, height: 30, overflow: 'hidden' }}>
            <Image
              source={require('../../../assets/images/ras-logo.jpg')}
              style={{ width: '100%', height: '100%' }}
              resizeMode="cover"
            />
          </View>
        </Link_C>
      )}

      <View style={{ flexDirection: 'row', marginLeft: 10, columnGap: 24 }}>
        {sortedAndFilteredTabs.map(([tabName, tabElem, tabConfig]) => {
          const isActive = activeTab === tabName
          const tabLabel = tabConfig?.label ?? tabName
          return (
            <Button_C
              key={tabName}
              styleType="tabButton"
              active={isActive}
              accessibilityState={{ selected: isActive }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              {...(tabConfig?.buttonIcon ? { icon: tabConfig.buttonIcon } : {})}
              onPress={() => {
                setActiveTab(tabName)
                props.onTabSelected?.(tabName)
              }}
              style={{
                elem: {
                  base: {
                    ...(!isActive &&
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      tabConfig?.lowPriority && {
                        backgroundColor: colors.gray200
                      })
                  }
                },
                text: {
                  base: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...(!isActive && tabConfig?.lowPriority && { color: colors.gray500 })
                  }
                }
              }}
            >
              {tabLabel}
            </Button_C>
          )
        })}
      </View>
    </ScrollHorizontal_C>
  )

  return separateCards ? (
    <>
      <Card_C style={styles.headerCard}>
        {header}
        {tabsElem}
      </Card_C>
      {outputView}
    </>
  ) : (
    <Card_C style={styles.headerCard}>
      {header}
      {tabsElem}
      <Separator_C />
      {outputView}
    </Card_C>
  )
}

const breakpointStyles = createStyles({
  headerCard: {
    gap: 10,
    position: 'relative',
    zIndex: 10
  },
  topContainer: {
    base: {
      rowGap: 10,
      zIndex: 1
    },
    small: {
      flexDirection: 'row',
      paddingHorizontal: 0,
      justifyContent: 'flex-start',
      alignItems: 'center',
      columnGap: 20
    }
  },
  leftContainer: {
    flex: 1,
    marginRight: 'auto'
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 20,
    zIndex: 1
  },
  scrollContainer: {
    flexDirection: 'row'
  }
})
