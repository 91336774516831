/*  */ import { useNavigation } from '@react-navigation/native'
import { format } from 'date-fns'
import { Fragment, useContext, useEffect, useState } from 'react'
import { Platform, View } from 'react-native'

import { desaturateColor, numberToOrdinal } from '../../../../../../helper-functions'
import {
  MeetingDetailedRace,
  RunnerBasic
} from '../../../../../../services/data/data-types/local-data-types/meeting-detailed.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../../../../services/styles/breakpoint-styles.service'
import {
  desktopBreakpoint,
  tabletBreakpoint
} from '../../../../../../services/styles/dependencies/style-constants'
import { colors, presets } from '../../../../../../styles/colors'
import { textSizes } from '../../../../../../styles/text-sizes'
import { humanFriendlyDate, shortDate } from '../../../../../../utils/date-time'
import { savePageInHistory } from '../../../../../../utils/save-history'
import { Button_C } from '../../../../../Base/Button/Button'
import { Icon_C } from '../../../../../Base/Icon/Icon'
import { blackbookRibbon, chevronIcon, playIcon } from '../../../../../Base/Icon/preset-icon-props'
import { Switch_C } from '../../../../../Base/Switch/Switch'
import { Table_C, tableStyles } from '../../../../../Base/Table/Table'
import { TableHeading, TableRow } from '../../../../../Base/Table/table.type'
import { Text_C } from '../../../../../Base/Text/Text'
import { ExpandContainer_C } from '../../../../../Layout/ExpandContainer/ExpandContainer'
import { LinkOrText_C } from '../../../../../Partials/LinkOrText'
import { SilkImage_C } from '../../../../../Partials/SilkImage/SilkImage'
import { MeetingContext } from '../../../Meeting'
import { ExpandedRunner_C } from './ExpandedRunner'
import { useAtom } from 'jotai'
import { includeRunnerTrialsAtom } from '../../../../../../atoms'
import { getVideoReplay } from '../../../../../../services/data/request-functions/videos-show-request'
import { openPlayerDialog } from '@onpace/onspace-media-react/components/player'
import { getVideoSource } from '../../../../../../services/data/request-functions/video-source-request'

type Props = {
  race: MeetingDetailedRace
}

type State = {
  allRunnersExpanded: boolean
  expandedRunnerNumber: number | undefined
}

export function FieldTable_C(props: Props) {
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const {
    blackbookHorseIds,
    activeMeeting: { isTrial }
  } = useContext(MeetingContext)

  const navigation = useNavigation()

  // NOTE: This will only work on web. This needs to be refactored to work on mobile
  const searchParams = new URLSearchParams(window.location.search)

  const expandedRunnerParam = searchParams.get('expandedRunner')

  const [state, setState] = useState<State>({
    allRunnersExpanded: !!expandedRunnerParam && expandedRunnerParam === 'all',
    expandedRunnerNumber:
      expandedRunnerParam && expandedRunnerParam !== 'all' ? Number(expandedRunnerParam) : undefined
  })

  const [includeTrials, setIncludeRunnerTrials] = useAtom(includeRunnerTrialsAtom)

  const isMobileLayout = breakpointBelow(tabletBreakpoint)
  const isTabletLayout = breakpointBelow(desktopBreakpoint)

  const { mobileTable, expandAllSwitch, includeRunnerTrials, desktopTable } = setup()

  useEffect(() => {
    updateSearchParams(state.allRunnersExpanded, state.expandedRunnerNumber, includeTrials)
  }, [state.allRunnersExpanded, state.expandedRunnerNumber, includeTrials])

  function updateSearchParams(
    allExpanded: boolean,
    expandedRunnerNumber?: number,
    includeTrials?: boolean
  ) {
    if (Platform.OS !== 'web') return

    const searchParams = new URLSearchParams(location.search)
    if (allExpanded) {
      searchParams.set('expandedRunner', 'all')
    } else if (expandedRunnerNumber !== undefined) {
      searchParams.set('expandedRunner', String(expandedRunnerNumber))
    } else {
      searchParams.delete('expandedRunner')
    }
    searchParams.set('includeTrials', String(includeTrials))
    history.replaceState({}, '', `${location.pathname}?${searchParams.toString()}`)
  }

  return isMobileLayout ? (
    mobileTable
  ) : (
    <View style={{ gap: 10 }}>
      <View style={{ flexDirection: 'row', gap: 20 }}>
        {expandAllSwitch}
        {includeRunnerTrials}
      </View>

      {desktopTable}
    </View>
  )

  function setup() {
    const expandAllSwitch = (
      <View style={styles.expandSwitch}>
        <Switch_C
          onSwitched={(val) => {
            setState((prev) => ({
              ...prev,
              allRunnersExpanded: val
            }))
          }}
          isEnabled={state.allRunnersExpanded}
        />
        <Text_C style={mobileStyles.headingText}>Expand All Form</Text_C>
      </View>
    )

    const includeRunnerTrials = (
      <View style={styles.expandSwitch}>
        <Switch_C
          onSwitched={(val) => {
            setIncludeRunnerTrials(val)
          }}
          isEnabled={includeTrials}
        />
        <Text_C style={mobileStyles.headingText}>Include trials</Text_C>
      </View>
    )

    const mobileHeadings: TableHeading[] = [
      {
        content: (
          <View style={{ gap: 20, flexDirection: 'row' }}>
            {expandAllSwitch}
            {includeRunnerTrials}
          </View>
        )
      },
      ...(isTrial ? [] : [{ content: 'Odds', colWidth: { width: 100 } }])
    ]
    const mobileRows: TableRow[] = !isMobileLayout
      ? []
      : props.race.runners.map((runner) => {
          const {
            trainer,
            driver,
            form,
            horse,
            handicap,
            runnerNumber,
            odds,
            silk,
            driverConcession
          } = runner

          const isExpanded =
            state.allRunnersExpanded ||
            (!!state.expandedRunnerNumber && state.expandedRunnerNumber === runnerNumber)

          return {
            underRowContent: (
              <ExpandContainer_C isExpanded={isExpanded}>
                <ExpandedRunner_C meetingRunner={runner} raceNumber={props.race.raceNumber} />
              </ExpandContainer_C>
            ),

            onSelected: () => expandRunnerRow(runner),
            cells: [
              {
                content: (
                  <View style={mobileStyles.firstCol}>
                    <View style={{ alignSelf: 'flex-end' }}>
                      <SilkImage_C silk={silk} />
                      <Text_C style={mobileStyles.formText}>{form}</Text_C>
                    </View>
                    <View style={{ rowGap: 2 }}>
                      <Text_C style={runner.scratched ? styles.runnerScratched : {}}>
                        <LinkOrText_C
                          name={`${runner.runnerNumber}. ${horse.name}`}
                          linkUrl={!horse.slug ? undefined : `horse/${horse.slug}`}
                          newTab
                          linkStyle={{
                            text: {
                              base: {
                                ...styles.runner,
                                ...(runner.scratched ? styles.runnerScratched : {})
                              }
                            }
                          }}
                        />
                        ({handicap}
                        {runner.isLateScratching ? '(L)' : ''})
                      </Text_C>
                      <View>
                        {driver && (
                          <Text_C
                            style={{
                              ...textSizes.size2,
                              ...(runner.scratched ? styles.runnerScratched : {})
                            }}
                          >
                            D:{' '}
                            <LinkOrText_C
                              newTab
                              name={`${driver.name ?? driver.shortName}${
                                driverConcession ? ` ${driverConcession}` : ''
                              }`}
                              linkUrl={!driver.slug ? undefined : `driver/${driver.slug}`}
                              linkStyle={{
                                text: {
                                  ...(runner.scratched ? styles.runnerScratched : {})
                                }
                              }}
                            />
                          </Text_C>
                        )}
                        {trainer && (
                          <Text_C style={runner.scratched ? styles.runnerScratched : {}}>
                            T:{' '}
                            <LinkOrText_C
                              newTab
                              name={trainer.name ?? trainer.shortName}
                              linkUrl={!trainer.slug ? undefined : `trainer/${trainer.slug}`}
                              linkStyle={{
                                text: {
                                  ...(runner.scratched ? styles.runnerScratched : {})
                                }
                              }}
                            />
                          </Text_C>
                        )}
                      </View>
                    </View>
                    {isRunnerInBlackBook(runner) && <Icon_C {...blackbookRibbon} />}
                  </View>
                )
              },
              {
                content: (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      gap: 6
                    }}
                  >
                    {odds && <Text_C>{odds}</Text_C>}
                    <Icon_C
                      {...chevronIcon}
                      color={colors.gray600}
                      style={{
                        marginLeft: 'auto',
                        width: 20,
                        transform: [{ rotate: isExpanded ? '0deg' : '-90deg' }]
                      }}
                    />
                  </View>
                )
              }
            ]
          }
        })

    const desktopHeadings: (string | TableHeading)[] = isMobileLayout
      ? []
      : [
          { content: 'No.', colWidth: { width: 60 } },
          { content: 'Colours', colWidth: { width: 78 } },
          { content: 'Form', colWidth: { width: 60 } },
          {
            content: 'Horse / Driver / Trainer',
            colWidth: { width: isTabletLayout ? 340 : 400 }
          },
          ...(isTabletLayout ? [] : [{ content: 'Other Eng', colWidth: { flex: 2 } }]),
          { content: 'Class', colWidth: { flex: 2 } },
          { content: 'Hcp', colWidth: { width: 60 } },
          {
            content: 'Last Run',
            colWidth: { width: 190 }
          },
          ...(isTrial ? [] : [{ content: 'Odds', colWidth: { width: 74 } }])
        ]

    const desktopRows: TableRow[] = isMobileLayout
      ? []
      : props.race.runners.map((runner) => {
          const {
            trainer,
            driver,
            form,
            horse,
            handicap,
            otherEng,
            lastRun,
            runnerNumber,
            odds,
            silk,
            driverConcession,
            odStatus,
            claimingPrice,
            emergencyNumber
          } = runner

          const isExpanded =
            state.allRunnersExpanded ||
            (!!state.expandedRunnerNumber && state.expandedRunnerNumber === runnerNumber)

          function onLastRunClick() {
            savePageInHistory()
            const { meetingDate, trackSlug, dayPhaseLetter } = lastRun!
            navigation.navigate('Meeting', {
              date: shortDate(meetingDate),
              trackSlug: trackSlug,
              dayPhaseLetter: dayPhaseLetter,
              type: 'meeting' // todo: needs isTrial to build correct link
            })
          }

          const expandedRunner = (
            <ExpandContainer_C isExpanded={isExpanded}>
              <ExpandedRunner_C meetingRunner={runner} raceNumber={props.race.raceNumber} />
            </ExpandContainer_C>
          )

          const runnerRows: TableRow = {
            underRowContent: expandedRunner,
            onSelected: () => expandRunnerRow(runner),
            cells: [
              {
                style: {
                  ...styles.runnerNumberStyle,
                  ...(isExpanded && { backgroundColor: presets.primary })
                },
                content: (
                  <Fragment>
                    <Text_C style={{ ...(isExpanded && { color: 'white' }) }}>
                      {runnerNumber}
                    </Text_C>

                    <Icon_C
                      name="chevron"
                      style={{
                        width: 20,
                        height: 10
                      }}
                      color={isExpanded ? 'white' : colors.gray600}
                      strokeWidth={1.5}
                    />
                  </Fragment>
                )
              },
              {
                content: <SilkImage_C silk={silk} />
              },
              {
                content: form?.toString()
              },
              {
                style: { position: 'relative' },
                content: (
                  <Fragment>
                    <View style={styles.linksContainer}>
                      <LinkOrText_C
                        name={`${horse.name}${
                          claimingPrice ? ` ($${claimingPrice})` : ''
                        }${emergencyNumber ? ` (Em ${emergencyNumber})` : ''}`}
                        newTab
                        linkUrl={!horse.slug ? undefined : `horse/${horse.slug}`}
                        linkStyle={{
                          text: {
                            base: {
                              ...styles.runner,
                              ...(runner.scratched ? styles.runnerScratched : {})
                            }
                          }
                        }}
                      />
                      <View style={{ flexDirection: 'row' }}>
                        <Text_C style={runner.scratched ? styles.runnerScratched : {}}>
                          {driver && (
                            <Fragment>
                              D:{' '}
                              <LinkOrText_C
                                newTab
                                name={`${driver.name ?? driver.shortName}${
                                  driverConcession ? ` ${driverConcession}` : ''
                                }`}
                                linkUrl={!driver.slug ? undefined : `driver/${driver.slug}`}
                                linkStyle={{
                                  text: {
                                    ...(runner.scratched ? styles.runnerScratched : {})
                                  }
                                }}
                              />
                            </Fragment>
                          )}
                          {driver && trainer && ' | '}
                          {trainer && (
                            <Fragment>
                              T:{' '}
                              <LinkOrText_C
                                newTab
                                name={trainer.name ?? trainer.shortName}
                                linkUrl={!trainer.slug ? undefined : `trainer/${trainer.slug}`}
                                linkStyle={{
                                  text: {
                                    ...(runner.scratched ? styles.runnerScratched : {})
                                  }
                                }}
                              />
                            </Fragment>
                          )}
                        </Text_C>
                      </View>
                    </View>
                    {isRunnerInBlackBook(runner) && <Icon_C {...blackbookRibbon} />}
                  </Fragment>
                )
              },
              ...(isTabletLayout // hide other eng on tablet
                ? []
                : [
                    {
                      content: otherEng?.map((r) => (
                        <Button_C
                          key={r.raceNumber}
                          styleType="linkBase"
                          onPress={() => {}}
                        >{`${format(new Date(r.date), 'dd-MMM-yyyy')} ${r.name}`}</Button_C>
                      ))
                    }
                  ]),
              { content: runner.class },
              {
                content: `${handicap}${runner.isLateScratching ? '(L)' : ''}${
                  odStatus ? `\n${odStatus}` : ''
                }`
              },
              {
                content: !lastRun ? (
                  ''
                ) : (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      columnGap: 12
                    }}
                  >
                    <Button_C styleType="linkBase" onPress={onLastRunClick}>
                      {`${humanFriendlyDate(lastRun.meetingDate)}\n${lastRun.trackNameShort}\n${
                        isNaN(Number(lastRun.place))
                          ? lastRun.place
                          : numberToOrdinal(Number(lastRun.place))
                      } place`}
                    </Button_C>

                    {!!lastRun.hasReplay && (
                      <View>
                        <Button_C
                          style={{
                            text: {
                              base: { color: presets.primary },
                              hovered: {
                                color: desaturateColor(presets.primary, 0.5)
                              }
                            }
                          }}
                          styleType="iconOnly"
                          onPress={onLastRunClick}
                        />

                        <Button_C
                          style={{
                            text: {
                              base: { color: presets.primary },
                              hovered: {
                                color: desaturateColor(presets.primary, 0.5)
                              }
                            }
                          }}
                          styleType="iconOnly"
                          icon={playIcon}
                          onPress={async () => {
                            const { raceReplay } = await getVideoReplay({
                              date: shortDate(lastRun.meetingDate),
                              trackSlug: lastRun.trackSlug,
                              raceNumber: Number(lastRun.raceNumber),
                              isTrial: false
                            })

                            openPlayerDialog(raceReplay.viewerType, {
                              metadata: {
                                title: raceReplay.title,
                                subtitle: raceReplay.description,
                                artwork: raceReplay.placeholderImageUrl
                              },
                              ...(raceReplay.viewerType === 'iframe' ||
                              raceReplay.viewerType === 'clickout'
                                ? { sources: raceReplay.viewerSources }
                                : {
                                    sourcesLoader: async () => {
                                      const { playerSources } = await getVideoSource({
                                        date: shortDate(lastRun.meetingDate),
                                        trackSlug: lastRun.trackSlug,
                                        raceNumber: Number(lastRun.raceNumber),
                                        isTrial: false,
                                        videoIndex: 0
                                      })
                                      return { sources: playerSources }
                                    }
                                  })
                            })
                          }}
                        />
                      </View>
                    )}
                  </View>
                )
              },

              ...(isTrial ? [] : [{ content: odds ? odds : 'N/A' }])
            ]
          }
          return runnerRows
        })

    const mobileTable = !isMobileLayout ? null : (
      <Table_C
        headings={mobileHeadings}
        rows={mobileRows}
        styles={{
          tableContainer: { base: { flex: 1 } },
          table: { base: { minWidth: 300, width: '100%' } },
          headingText: { base: mobileStyles.headingText },
          row: {
            base: mobileStyles.tableBorder
          },
          header: {
            base: mobileStyles.tableBorder
          }
        }}
      />
    )

    const desktopTable = isMobileLayout ? null : (
      <Table_C
        styleType="lightHeaderBorders"
        headings={desktopHeadings}
        rows={desktopRows}
        styles={{ table: styles.table, tableContainer: styles.tableContainer }}
      />
    )
    return {
      mobileTable,
      expandAllSwitch,
      includeRunnerTrials,
      desktopTable
    }
  }

  function expandRunnerRow(runner: RunnerBasic) {
    setState((prevState) => ({
      ...prevState,
      expandedRunnerNumber:
        runner.runnerNumber === prevState.expandedRunnerNumber ? undefined : runner.runnerNumber
    }))
  }

  function isRunnerInBlackBook(runner: RunnerBasic): boolean {
    return blackbookHorseIds?.includes(runner.horse.id) ?? false
  }
}

const breakpointStyles = createStyles({
  tableContainer: { width: '100%' },
  table: { minWidth: 900, width: '100%' },
  expandSwitch: {
    base: {
      flexDirection: 'column-reverse',
      gap: 10,
      marginTop: 8
    },
    [tabletBreakpoint]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8
    }
  },
  silkSvg: {
    width: 37.2
  },
  runnerNumberStyle: {
    base: {
      ...tableStyles.cell,
      ...textSizes.size3,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10
    },
    [desktopBreakpoint]: {
      ...tableStyles.cell
    }
  },
  linksContainer: {
    alignItems: 'flex-start',
    gap: 4
  },
  runnerScratched: {
    textDecorationLine: 'line-through'
  },
  runner: {
    base: {
      ...textSizes.size4,
      fontWeight: '600SemiBold',
      marginRight: 4
    }
  }
})

const mobileStyles = createStyles({
  firstCol: { flexDirection: 'row', gap: 20, alignItems: 'center' },
  formText: {
    ...textSizes.size1,
    color: colors.gray600,
    textAlign: 'center',
    marginTop: 2
  },
  headingText: {
    ...textSizes.size1,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold'
  },
  tableBorder: {
    borderBottomWidth: 1,
    borderBottomColor: presets.border
  }
})
