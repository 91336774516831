import { useEffect, useState } from 'react'
import { Image, ImageSourcePropType, ImageStyle, View } from 'react-native'

import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { colors, presets } from '../../../styles/colors'
import { Asset } from '../../../types/assets.type'
import { Icon_C } from '../../Base/Icon/Icon'
import { Link_C } from '../../Base/Link/Link'
import { PressableStyleExtension, Pressable_C } from '../../Base/Pressable/Pressable'
import { openPlayerDialog } from '@onpace/onspace-media-react/components/player'

import fallbackVideoImage from '../../../assets/images/logo-light.png'
import { desaturateColor } from '../../../helper-functions'
import { textSizes } from '../../../styles/text-sizes'
import { playIcon } from '../../Base/Icon/preset-icon-props'
import { getVideoSource } from '../../../services/data/request-functions/video-source-request'
import { DayPhaseLetter } from '../../../services/data/data-types/general-data-types.type'
import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'
import { Button_C } from '../../Base/Button/Button'
import { Text_C } from '../../Base/Text/Text'

export type VideoItemProps = {
  date: string
  nextVideos?: Asset[] | false
  raceNumber: number
  startTime: string
  trackSlug: string
  trial: boolean
  video: Asset
  dayPhaseLetter: DayPhaseLetter
  style?: NamedBreakpointStylesExtension<typeof stylesObj>
  includeMeetingLink?: boolean
  asButton?: boolean
}

export function VideoItem_C({
  date,
  startTime,
  video,
  nextVideos,
  trackSlug,
  raceNumber,
  dayPhaseLetter,
  includeMeetingLink = true,
  style,
  trial,
  asButton = false
}: VideoItemProps) {
  const [videos, setVideos] = useState<Asset[]>([video])
  const styles = useBreakpointStyles({
    styles: stylesObj,
    additionalStyles: [style]
  })

  useEffect(() => {
    if (nextVideos && nextVideos.length > 0) {
      setVideos([...videos, ...nextVideos])
    }
  }, [nextVideos])

  async function sourcesLoader({ mediaId }: { mediaId: string }) {
    const vid = videos.find((v) => mediaId === v.id)

    const { playerSources } = await getVideoSource({
      trackSlug,
      date,
      raceNumber,
      isTrial: trial,
      videoIndex: 0,
      isStewardsReplay: vid?.isStewardsReplay
    })

    return { sources: playerSources }
  }

  function createMetadata(video: Asset) {
    return {
      id: video.id,
      title: video.isStewardsReplay ? `Stewards Replay - ${video.title}` : video.title,
      subtitle: video.description,
      artwork: video.placeholderImageUrl ?? fallbackVideoImage
    }
  }

  function handleOpenVideoDialog() {
    const player = openPlayerDialog(video.viewerType, {
      metadata: createMetadata(video),
      ...(video.viewerType === 'iframe' || video.viewerType === 'clickout'
        ? { sources: video.viewerSources }
        : {
            sourcesLoader,
            upNext: {
              metadata: nextVideos && nextVideos.length > 0 ? createMetadata(nextVideos[0]) : null
            },
            automaticPlayInterval: 5,
            onMediaChanged: (mediaId: string) => {
              const currentVid = videos.find((v) => v?.id === mediaId) || videos[0]
              const index = videos.indexOf(currentVid)
              let nextVid = null
              if (index < videos.length - 1) {
                nextVid = videos[index + 1]
              }
              player.metadata = createMetadata(currentVid)
              player.setUpNext({ metadata: nextVid ? createMetadata(nextVid) : null })
            },
            ...(nextVideos &&
              nextVideos.length > 0 && {
                tabs: [
                  { title: 'More', type: 'selector', items: videos.map((v) => createMetadata(v)) }
                ]
              })
          })
    })
  }

  if (asButton) {
    return (
      <View style={styles.replayButtonContainer}>
        <Button_C
          styleType="iconOnly"
          style={replayAndPhotoButton}
          onPress={handleOpenVideoDialog}
          icon={{
            ...playIcon,
            style: styles.replayPlayIcon
          }}
        >
          <Text_C>Replay</Text_C>
        </Button_C>
      </View>
    )
  }

  return (
    <View style={styles.mainContainer}>
      <Pressable_C onPress={handleOpenVideoDialog}>
        <Image
          source={(video.placeholderImageUrl || fallbackVideoImage) as ImageSourcePropType}
          style={styles.videoImage as ImageStyle}
          resizeMode="contain"
        />

        <View style={styles.playIconContainer}>
          <Icon_C
            {...{
              ...playIcon,
              style: { width: 50, height: 50 },
              color: 'white'
            }}
          />
        </View>
      </Pressable_C>

      {includeMeetingLink && (
        <Link_C
          navigateTo={[
            'Meeting',
            { date, trackSlug, dayPhaseLetter, raceNumber: `${raceNumber}`, type: 'meeting' }
          ]}
          style={titleLink}
        >
          {`${startTime} - ${video.title}`}
        </Link_C>
      )}
    </View>
  )
}

const titleLink: PressableStyleExtension = {
  text: {
    base: {
      marginLeft: 2,
      ...textSizes.size3,
      fontWeight: '600SemiBold',
      color: colors.gray800
    },
    hovered: { color: desaturateColor(presets.primary, 0.5) }
  }
}

export const stylesObj = createStyles({
  mainContainer: {
    gap: 6
  },
  videoImageContainer: {
    aspectRatio: 1.7889
  },
  title: {},
  videoImage: {
    aspectRatio: 1.7889,
    height: 0,
    paddingTop: '56.25%',
    backgroundColor: presets.headerFooter,
    borderRadius: 5
  },
  playIconContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  },
  playIcon: {
    width: 50,
    height: 50
  },
  replayButtonContainer: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center'
  },
  replayPlayIcon: {
    width: 28,
    height: 28
  },
  raceOffText: {
    fontSize: 10,
    fontStyle: 'italic',
    fontWeight: '600SemiBold'
  }
})

const replayAndPhotoButton: PressableStyleExtension = {
  elem: {
    base: {
      flexDirection: 'row',
      gap: 10,
      alignItems: 'center'
    }
  },
  text: {
    base: {
      ...textSizes.size1,
      textTransform: 'uppercase',
      fontWeight: '600SemiBold',
      color: presets.primary
    },
    hovered: { color: desaturateColor(presets.primary, 0.5) }
  }
}
