import { fetchPdf } from '../api-fetch-request'
import { DayPhaseLetter } from '../data-types/general-data-types.type'

export type PrintType = 'fields' | 'form' | 'sectionals'

export async function printPDF({
  track,
  date,
  dayPhase,
  print = 'fields',
  includeRunnerTrials = false
}: {
  track: string
  date: string
  dayPhase: DayPhaseLetter
  print: PrintType
  includeRunnerTrials?: boolean
}) {
  const baseEndpoint = `racing/${track}/${date}/${dayPhase}/`
  const sectionalPath = print === 'sectionals' ? 'sectional-times/' : ''
  const queryParams = `export?print=${print}&include_runner_trials=${includeRunnerTrials}`
  const endpoint = `${baseEndpoint}${sectionalPath}${queryParams}`

  try {
    const response = await fetchPdf({ endpoint })
    const blob = response.blob()
    return blob
  } catch (error: unknown) {
    const errorMessage = 'Failed to generate the PDF'
    throw new Error(errorMessage)
  }
}
