/* eslint-disable no-unused-vars */
import { useNavigation } from '@react-navigation/native'
import { isSameDay, isToday, isTomorrow, isYesterday } from 'date-fns'
import { orderBy } from 'lodash'
import { Fragment, useMemo, useState } from 'react'
import { View } from 'react-native'
import { australianStates } from '../../../constants'
import { DateTabsResponse, makeDateTabs, toCapitalized } from '../../../helper-functions'
import { MeetingStatus } from '../../../services/data/data-types/general-data-types.type'
import { MeetingBasic } from '../../../services/data/data-types/local-data-types/meeting-basic.type'
import {
  breakpointBelow,
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../services/styles/dependencies/style-constants'
import { textSizes } from '../../../styles/text-sizes'
import { AustralianState } from '../../../types/map.type'
import { humanFriendlyDate, shortDate } from '../../../utils/date-time'
import { savePageInHistory } from '../../../utils/save-history'
import { Select_C } from '../../Base/Select/Select'
import { Switch_C } from '../../Base/Switch/Switch'
import { Text_C } from '../../Base/Text/Text'
import { Card_C } from '../../Layout/Card/Card'
import { TabConfigItem, TabView_C } from '../../Layout/TabView/TabView'
import { CalendarPickerIcon_C } from '../CalendarPicker/CalendarPickerIcon'
import { MeetingListTable_C } from './MeetingListTable'

type PropsHome = {
  activeDate: Date
  type: 'home'
  meetings: MeetingBasic[]
  homeHeading: JSX.Element
  handleDateSelected?(date: Date): void
  minDate?: Date
  maxDate: Date
}
type PropsFieldOrResults = {
  activeDate: Date
  type: 'fields' | 'results'
  meetings: MeetingBasic[]
  handleDateSelected?(date: Date): void
  minDate?: Date
  maxDate: Date
}
export function MeetingListCard_C(props: PropsHome): JSX.Element
export function MeetingListCard_C(props: PropsFieldOrResults): JSX.Element
export function MeetingListCard_C(props: PropsHome | PropsFieldOrResults) {
  const { activeDate, type, meetings, handleDateSelected, maxDate, minDate } = props

  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const isMobileLayout = breakpointBelow('medium')
  const dateString = shortDate(activeDate)
  const [selectedState, setSelectedState] = useState<AustralianState>()

  const [includeTrials, setIncludeTrials] = useState<boolean>()

  const { navigate } = useNavigation()

  const sortedFilteredMeetings = getSortedFilteredMeetings()

  const { card, headerTools } = getElements()

  if (type == 'home') {
    return (
      <Card_C style={styles.meetingCard}>
        <View style={styles.topContainer}>
          <View style={styles.leftContainer}>{props.homeHeading}</View>

          <View style={styles.rightContainer}>{headerTools}</View>
        </View>
        <MeetingListTable_C date={dateString} meetings={sortedFilteredMeetings} />
      </Card_C>
    )
  }

  const { activeTabName, tabConfig, tabNameAndDateArray } = getTabVars()

  return (
    <TabView_C
      initialActiveTab={activeTabName}
      onTabSelected={handleTabSelected}
      tabs={tabConfig}
      heading={toCapitalized(type)}
    >
      {headerTools}
    </TabView_C>
  )

  function getElements() {
    const hasTrials = meetings.some((m) => m.isTrial && m.races.length)

    const noMeetingsMessage = selectedState
      ? `No ${selectedState} ${type}`
      : `No ${type} ${
          isToday(activeDate)
            ? 'today'
            : isTomorrow(activeDate)
              ? 'tomorrow'
              : isYesterday(activeDate)
                ? 'yesterday'
                : humanFriendlyDate(activeDate)
        }`

    const card = (
      <Card_C noMobileHorizontalPadding style={styles.meetingCard}>
        {meetings?.length ? (
          <MeetingListTable_C date={dateString} meetings={sortedFilteredMeetings} />
        ) : (
          <Text_C style={styles.noMeetingMessage}>{noMeetingsMessage}</Text_C>
        )}
      </Card_C>
    )

    const selectOptions = useMemo(
      () => [
        { label: 'All States', value: undefined },
        ...australianStates.map((state) => ({
          label: state,
          value: state
        }))
      ],
      []
    )

    const selectElem = useMemo(
      () => (
        <Select_C<AustralianState | undefined>
          styleType="blueCaret"
          options={selectOptions}
          initialSelection={
            selectedState
              ? selectOptions.find((option) => option.value == selectedState)?.label
              : selectOptions[0].label
          }
          onSelect={(selected) => setSelectedState(selected.value)}
        />
      ),
      []
    )

    const headerTools = (
      <Fragment>
        {hasTrials && (
          <View style={styles.includeTrialsSwitch}>
            <Switch_C onSwitched={setIncludeTrials} isEnabled={includeTrials} />
            <Text_C style={styles.includeTrialsText}>Include Trials</Text_C>
          </View>
        )}

        {selectElem}

        <CalendarPickerIcon_C
          maxDate={maxDate}
          minDate={minDate}
          selectedDate={activeDate}
          onDateSelected={(date) => {
            if (type == 'home' && handleDateSelected) {
              handleDateSelected(date)
            } else {
              savePageInHistory()
              navigate(type == 'results' ? 'Results' : 'Fields', {
                date: shortDate(date)
              })
            }
          }}
        />
      </Fragment>
    )

    return { card, headerTools }
  }

  function getTabVars(): {
    activeTabName: string
    tabConfig: TabConfigItem[]
    tabNameAndDateArray: DateTabsResponse[]
  } {
    const daysRange = isMobileLayout ? 2 : 3

    const tabNameAndDateArray =
      type == 'results'
        ? makeDateTabs({
            type: 'results',
            daysRange,
            activeDate,
            maxDate
          })
        : makeDateTabs({
            type: 'fields',
            daysRange: 14, // there should never be enough data to reach this threshold
            activeDate,
            maxDate
          })

    const activeTabName = tabNameAndDateArray.find(({ selected }) => selected)!.tabName

    if (!activeTabName) {
      // eslint-disable-next-line no-console
      console.error('should have found active tab name')
    }

    const tabConfig: TabConfigItem[] = tabNameAndDateArray?.map(({ tabName, date }) => [
      tabName,
      isSameDay(date, activeDate) ? card : <Fragment></Fragment>,
      {}
    ])
    return { activeTabName: activeTabName, tabConfig, tabNameAndDateArray }
  }

  function getSortedFilteredMeetings() {
    const filteredMeetings = meetings?.filter((meeting) => {
      /* meeting in list if all are true:
    - status is fields drawn or results
    - has races
    - is state match
    - is trial match */
      if (
        (['Fields Drawn', 'Results'] as MeetingStatus[]).includes(meeting.status) &&
        meeting.races.length &&
        (selectedState === undefined || selectedState === meeting.track.state) &&
        (!meeting.isTrial || includeTrials)
      ) {
        return meeting
      }
    })

    const sortedFilteredMeetings = orderBy(
      filteredMeetings,
      [
        (m) => !m.isTrial,
        ({ races }) => {
          const [first] = orderBy(races, (race) => race.startTime, ['asc'])
          return first.startTime
        }
      ],
      ['desc', 'asc']
    )
    return sortedFilteredMeetings
  }

  function handleTabSelected(selectedTabName: string) {
    const tab = tabNameAndDateArray?.find(({ tabName }) => tabName == selectedTabName)
    if (!tab) {
      // eslint-disable-next-line no-console
      console.warn('should have found tabConfig value')
    } else {
      savePageInHistory()
      navigate(type == 'results' ? 'Results' : 'Fields', {
        date: shortDate(tab.date)
      })
    }
  }
}

const breakpointStyles = createStyles({
  headerCard: {
    gap: 10,
    position: 'relative',
    zIndex: 10
  },
  headerContent: {
    position: 'relative',
    zIndex: 10
  },
  topContainer: {
    base: {
      rowGap: 10,
      zIndex: 1
    },
    small: {
      flexDirection: 'row',
      paddingHorizontal: 0,
      justifyContent: 'flex-start',
      alignItems: 'center',
      columnGap: 20
    }
  },
  leftContainer: {
    flex: 1,
    marginRight: 'auto'
  },
  rightContainer: {
    marginLeft: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 20,
    zIndex: 1
  },
  includeTrialsSwitch: {
    base: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10
    }
  },
  includeTrialsText: {
    ...textSizes.size1,
    fontFamily: 'OpenSans',
    fontWeight: '600SemiBold'
  },
  meetingCard: {
    base: { zIndex: 1, gap: 18 },
    small: { gap: 24 },
    [tabletBreakpoint]: { gap: 30 }
  },
  noMeetingMessage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 20,
    fontStyle: 'italic'
  }
})
