/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { Animated, View } from 'react-native'
import {
  createStyles,
  useBreakpointStyles
} from '../../../services/styles/breakpoint-styles.service'
import { NamedBreakpointStylesExtension } from '../../../services/styles/dependencies/breakpoint-style.type'

/*
  requirement
  - animate height between 0 and height of the content
  - should not render content when closed
  - should finish animating closed before considered closed
  - to animate to the height of the content, the hight must be measured
  - to measure the height the content needs to be rendered but hidden
  - when the screen width changes the measured hight could change, so the height needs to be re-measured on screen width change
  - when the child component loads async, should trigger height calculation
  - the idea of 'isFullyExpanded' is for overflow hidden, when fully expanded, content should be able to overflow,
*/

type Props = {
  isExpanded: boolean | undefined
  /** in milliseconds */
  animationDuration?: number
  styles?: NamedBreakpointStylesExtension<typeof breakpointStyles>
}
export function ExpandContainer_C(props: PropsWithChildren<Props>) {
  const { isExpanded, children, animationDuration = 200 } = props
  const [contentHeight, setContentHeight] = useState<number | undefined>()
  const [renderContent, setRenderContent] = useState(false)
  const animateHeightRef = useRef(new Animated.Value(0))
  const containerElemRef = useRef<any>(null)
  const [isFullyExpanded, setIsFullyExpanded] = useState(false)

  const styles = useBreakpointStyles({
    styles: breakpointStyles,
    additionalStyles: [props.styles]
  })

  useEffect(() => {
    if (isExpanded && !renderContent) {
      setRenderContent(true)
    }
  }, [isExpanded])

  useEffect(() => {
    if (renderContent && isExpanded) {
      recordContentHeight()
    }
  }, [renderContent, isExpanded])

  function recordContentHeight() {
    const containerElem = containerElemRef.current
    const contentElem = containerElem?.firstChild
    if (containerElem && contentElem) {
      const contentHeight = contentElem.offsetHeight
      setContentHeight(contentHeight)
    }
  }

  /* set expand/collapse animations  */
  const animations = useMemo(() => {
    setIsFullyExpanded(false)
    if (contentHeight) {
      return {
        expand: Animated.timing(animateHeightRef.current, {
          toValue: contentHeight,
          duration: animationDuration,
          useNativeDriver: false
        }),
        collapse: Animated.timing(animateHeightRef.current, {
          toValue: 0,
          duration: animationDuration,
          useNativeDriver: false
        })
      }
    }
  }, [contentHeight])

  /* Animate to height when isExpanded or contentHeight changes */
  useEffect(() => {
    if (animations && contentHeight) {
      if (isExpanded) {
        animations.expand.start(({ finished }) => {
          finished && setIsFullyExpanded(true)
        })
      }

      if (!isExpanded) {
        setIsFullyExpanded(false)
        animations.collapse.start(({ finished }) => {
          //when close animation finishes, turn off render content
          if (finished) {
            setRenderContent(false)
          }
        })
      }
    }
  }, [isExpanded, contentHeight])

  if (!renderContent) return <Fragment></Fragment>
  return (
    <Animated.View
      ref={containerElemRef}
      style={[
        styles.expandContainer,
        { height: animateHeightRef.current },
        !isExpanded && styles.noBorders,
        { overflow: isFullyExpanded ? 'visible' : 'hidden' }
      ]}
    >
      {/* When child content loads async, onLayout triggers record height */}
      <View style={styles.innerContainer} onLayout={recordContentHeight}>
        {children}
      </View>
    </Animated.View>
  )
}

const breakpointStyles = createStyles({
  expandContainer: {},
  innerContainer: {},
  noBorders: { borderWidth: 0 }
})
