import { Fragment, useContext, useMemo } from 'react'
import {
  createStyles,
  useBreakpointStyles
} from '../../../../../services/styles/breakpoint-styles.service'
import { tabletBreakpoint } from '../../../../../services/styles/dependencies/style-constants'
import { Card_C } from '../../../../Layout/Card/Card'
import { MeetingContext } from '../../Meeting'
import { RaceHeader_C } from '../../components/RaceHeader'
import { FieldTable_C } from './components/FieldTable'

export function FieldsAndForm_C() {
  const meetingContext = useContext(MeetingContext)
  const styles = useBreakpointStyles({ styles: breakpointStyles })
  const { activeMeeting, selectedRaceNumber } = meetingContext

  return useMemo(() => {
    return (
      <Fragment>
        {activeMeeting.races
          .filter((r) => !selectedRaceNumber || selectedRaceNumber == r.raceNumber)
          .map((race) => (
            <Card_C style={styles.fieldAndFormCard} key={race.raceNumber}>
              <RaceHeader_C race={race} />
              <FieldTable_C race={race} />
            </Card_C>
          ))}
      </Fragment>
    )
  }, [selectedRaceNumber, activeMeeting])
}

const breakpointStyles = createStyles({
  fieldAndFormCard: {
    base: {
      gap: 10
    },
    [tabletBreakpoint]: {
      gap: 20
    }
  }
})
