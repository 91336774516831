/* eslint-disable no-console */
import { useContext } from 'react'
import { GlobalContext } from '../../global-context'
import { MeetingDetailed } from '../../services/data/data-types/local-data-types/meeting-detailed.type'
import { PrintType, printPDF } from '../../services/data/request-functions/meeting-pdf-request'
import { MeetingPuntersCorner } from '../../services/data/request-functions/punters-corner-request'
import { shortDate } from '../../utils/date-time'
import { Button_C } from '../Base/Button/Button'
import { printIcon } from '../Base/Icon/preset-icon-props'
import { titleCase } from '../../utils/word-case'

type PrintMeeting =
  | Pick<MeetingDetailed, 'track' | 'dayPhaseLetter' | 'date' | 'status'>
  | Pick<MeetingPuntersCorner, 'track' | 'dayPhaseLetter' | 'date' | 'status'>

export const PrintButton_C = ({
  meeting,
  printType,
  withColor,
  includeText,
  includeTrials
}: {
  meeting: PrintMeeting
  printType: PrintType
  withColor?: boolean
  includeText?: boolean
  includeTrials?: boolean
}) => {
  const { setLoadingSpinnerActive, openModal } = useContext(GlobalContext)

  const printMeeting = async (meeting: PrintMeeting, printType: PrintType) => {
    setLoadingSpinnerActive(true)
    const date = shortDate(new Date(meeting.date))
    try {
      const response = await printPDF({
        track: meeting.track.slug,
        date,
        dayPhase: meeting.dayPhaseLetter,
        print: printType,
        includeRunnerTrials: includeTrials
      })

      const file = window.URL.createObjectURL(response)
      return window.open(file)
    } catch (error) {
      openModal('Failed to generate the PDF')
      console.error(error)
    } finally {
      setLoadingSpinnerActive(false)
    }
  }

  return (
    <Button_C
      styleType={withColor ? 'colorIconOnly' : includeText ? 'buttonWithIconColor' : 'iconOnly'}
      icon={printIcon}
      onPress={() => printMeeting(meeting, printType)}
      style={{ elem: { width: '100%' } }}
    >
      {includeText ? `Print ${titleCase(printType)}` : null}
    </Button_C>
  )
}
