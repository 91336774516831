/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-unused-vars */
/*
  logic
  - page needs data, that data can come from 2 places
    ~ design mock, or api
  - design mock does not deal with the fetch type, only the converted type

  - some data requiers paramaters to construct the request
*/

import { API_ENV } from '@env'
import { environment } from './api-environment'
import { AllFetchResponseTypes, AllRequestResponseTypes } from './data-types/all-data-types'
import { validateFetchResponse } from './validate-fetch-response'

const { apiUrl, apiKey } = environment

/******* Data Fetch *******/

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

type ContentType =
  | 'application/json'
  | 'application/x-www-form-urlencoded'
  | 'multipart/form-data'
  | 'text/plain'

type Headers = {
  'Content-Type'?: ContentType
  Accept: string
  'API-KEY': string
  Authorization?: `Bearer ${string}`
}

type FetchDataParams<
  ConvertedResponseType extends AllRequestResponseTypes,
  FetchResponseType extends AllFetchResponseTypes
> = {
  endpoint: string
  validationCompareObject?: FetchResponseType
  convertResponse: (payload: FetchResponseType) => ConvertedResponseType
  body?: BodyInit
  headers?: Omit<Headers, 'API-KEY' | 'Accept'>
  method?: HttpMethod
}

export async function fetchData<
  ConvertedResponseType extends AllRequestResponseTypes,
  FetchResponseType extends AllFetchResponseTypes
>({
  endpoint,
  validationCompareObject,
  convertResponse,
  headers: propsHeaders,
  body,
  method = 'GET'
}: FetchDataParams<ConvertedResponseType, FetchResponseType>): Promise<ConvertedResponseType> {
  const requestUrl = `${apiUrl}/${endpoint}`
  const headers: Headers = {
    ...{
      Accept: 'application/json',
      'API-KEY': apiKey,
      'Content-Type': 'text/plain',
      cache: 'no-cache'
    },
    ...propsHeaders
  }

  const response: FetchResponseType = await fetch(requestUrl, {
    method,
    headers,
    body
  })
    .then((response) => {
      return response.json()
    })
    .catch((reason) => {
      console.error('Fetch request failed', reason)
      throw `Fetch request failed for request ${requestUrl}`
    })

  if (!response) {
    throw `No response for request ${requestUrl}`
  }

  if (response?.meta && response?.meta?.status != 200) {
    throw { error: 'response has invalid status', response }
  }

  if (validationCompareObject && API_ENV != 'production' && API_ENV != 'staging') {
    try {
      /* will throw if invalid */
      validateFetchResponse(response, validationCompareObject)
    } catch (error) {
      if (error) {
        console.error('Fetch response validation error ', error)
      }
      throw `Fetch response is invalid for ${requestUrl}`
    }
  }

  return convertResponse(response)
}

/*******  PDF Fetch *******/

export async function fetchPdf({ endpoint }: { endpoint: string }) {
  const url = `${apiUrl}/${endpoint}`
  return await fetch(url, {
    headers: {
      Accept: 'application/pdf',
      'API-KEY': apiKey
    }
  })
}
